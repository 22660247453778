import React, { useCallback, useState } from 'react';

import Section from '../../design_system/section/section';
import SectionTitle from '../../design_system/section/section_title/section_title';
import Pagination from '../../design_system/pagination/pagination';
import RotatedArrow from '../../small_views/rotated_arrow/rotated_arrow';

import ActualitySmallPost from './actuality_small_post/actuality_small_post';
import SortDropdown from './sort_dropdown/sort_dropdown';

import classes from './actualities_post_section.module.css';

const ActualitiesPostsSection = ({ pageCount, currentPage, actualityPosts }) => (
	<Section
		className={classes.container}
		setVisibleOnScroll
	>
		{({ showSection }) => (
			<div className={classes.content}>
				<div className={classes.topBar}>
					<SectionTitle
						containerClassName={classes.sectionTitleContainer}
						className={classes.sectionTitle}
						dismissUnderline={showSection}
						underlineClassName={classes.sectionTitleUnderline}
					>
						{'Tous les articles'}
					</SectionTitle>
					<div className={classes.filters}>
						<Sort />
					</div>
				</div>
				<div
					className={classes.postsContainer}
				>
					{Object.entries(actualityPosts).map(([id, actualityPost]) => (
						<ActualitySmallPost
							{...{
								id,
								actualityPost
							}}
						/>
					))}
				</div>
				<Pagination
					className={classes.pagination}
					useLink
					total={pageCount}
					current={currentPage}
					linkPathBuild={index => `/actus${index > 0 ? `/${index + 1}` : ''}#posts`}
				/>
			</div>
		)}
	</Section>
);

const Sort = () => {
	const [open, setOpen] = useState(false);
	const [anchorElement, setAnchorElement] = useState(null);
	const handleMouseEnter = useCallback((event) => {
		setAnchorElement(event.currentTarget);
		setOpen(true);
	});
	const handleMouseLeave = useCallback(() => {
		setOpen(false);
	});
	return (
		<button
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			onBlur={handleMouseLeave}
			type="button"
		>
			<SortDropdown
				popperProps={{
					placement: 'bottom',
					modifiers: {
						flip: {
							enabled: false
						},
						preventOverflow: {
							enabled: true,
							boundariesElement: 'scrollParent'
						}
					}
				}}
				{...{
					anchorElement,
					open
				}}
			/>
			<div className={classes.withDropdownText}>
				{'Du + récent au - récent'}
				<RotatedArrow rotationValue={open ? 90 : 0} className={classes.arrow} />
			</div>
		</button>
	);
}


export default ActualitiesPostsSection;
