import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';
import ActualitiesHeader from '../components/actualities/actualities_header/actualities_header';
import ActualitiesPostsSection from '../components/actualities/actualities_posts_section/actualities_posts_section';

const Actualities = ({
	pageContext: {
		actualityPosts,
		pageCount,
		currentPage
	}
}) => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account', 'trusting-customers-list']
			}}
		>
			<ActualitiesHeader />
			<ActualitiesPostsSection
				{...{
					actualityPosts,
					pageCount,
					currentPage
				}}
			/>
		</MainLayout>
	</HelmetLayout>
);

export default Actualities;
