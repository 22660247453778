import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'gatsby';

import moment from 'moment';

import Tag from '../../../design_system/tag/tag';
import Button from '../../../design_system/button/button';

import classes from './actuality_small_post.module.css';

const ActualitySmallPost = ({ id, actualityPost }) => {
	if (!id || !actualityPost) {
		return null;
	}
	const {
		preview, title, content, publishedAt
	} = actualityPost;
	const momentDate = useRef(moment(publishedAt));
	const newTags = useRef([
		{ label: momentDate.current.format('DD MMMM YYYY'), color: '#8b6706' }
	]);
	return (
		<div className={classes.container}>
			<div className={classes.tagsContainer}>
				{newTags.current.map(tag => (
					<Tag
						className={classes.tag}
						key={`tag_${tag.label}`}
						{...tag}
					/>
				))}
			</div>
			<div className={classes.title}>
				{title}
			</div>
			<div className={classes.previewContainer}>
				<img
					className={classes.previewPicture}
					src={preview}
					alt={title}
				/>
			</div>
			<div className={classes.content}>
				{content}
			</div>
			<div className={classes.actionsContainer}>
				<Link to={`actu/${id}`}>
					<DiscoverButton />
				</Link>
			</div>
		</div>
	);
}

const DiscoverButton = () => {
	const [color, setColor] = useState('emerald');
	const setColorPrimary = useCallback(() => setColor('primary'));
	const setColorEmerald = useCallback(() => setColor('emerald'));
	return (
		<Button
			className={classes.button}
			variant="underlined"
			bounce={false}
			onMouseEnter={setColorPrimary}
			onMouseLeave={setColorEmerald}
			onFocus={setColorPrimary}
			onBlur={setColorEmerald}
			{...{ color }}
		>
			{'En savoir plus >'}
		</Button>
	);
}

export default ActualitySmallPost;
