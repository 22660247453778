import React, { useCallback } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../../small_views/header/header';

import Background from '../../../assets/images/pages/actualities/header/background.jpg';
import BackgroundLowDef from '../../../assets/images/pages/actualities/header/background_low_def.jpg';

import classes from './actualities_header.module.css';

const ActualitiesHeader = () => {
	const useBackgroundClassName = useMediaQuery('(max-width: 850px)', { defaultMatches: true });
	const interpolation = useCallback(value => `translate3d(0, ${value - 350}px, 0)`);
	return (
		<Header
			dismissButtons
			className={classes.container}
			background={Background}
			backgroundLowDef={BackgroundLowDef}
			title={<Title />}
			titleClassName={classes.title}
			parallaxLayerProps={{
				customInterpolation: interpolation,
				staticMaxWidth: 850
			}}
			{...useBackgroundClassName && {
				backgroundClassName: classes.background
			}}
		/>
	);
}

const Title = () => (
	<>
		{'Accroche à venir pour cette partie Actus'}
		<br />
		{'définissant emplacement'}
		<br />
		{'et spécifications typographiques'}
	</>
);

export default ActualitiesHeader;
